@tailwind base;
@tailwind components;
@tailwind utilities;
@import "flatpickr/dist/themes/airbnb.css";

@layer base {
  * {
    @apply border-border;
  }
  :root {
    --azure-radiance-trace: 210, 100%, 99%;
    --azure-radiance-background: 210, 100%, 99%;
    --azure-radiance-50: 197, 100%, 96%;
    --azure-radiance-100: 200, 100%, 92%;
    --azure-radiance-200: 198, 100%, 85%;
    --azure-radiance-300: 196, 100%, 76%;
    --azure-radiance-400: 198, 100%, 64%;
    --azure-radiance-500: 203, 100%, 56%;
    --azure-radiance-600: 208, 100%, 51%;
    --azure-radiance-700: 212, 100%, 50%;
    --azure-radiance-800: 214, 92%, 40%;
    --azure-radiance-900: 213, 85%, 33%;
    --azure-radiance-950: 214, 74%, 21%;

    --red-50: 0, 100%, 98%;
    --red-100: 0, 100%, 95%;
    --red-200: 0, 100%, 90%;
    --red-300: 0, 100%, 83%;
    --red-400: 0, 100%, 74%;
    --red-500: 0, 100%, 50%;
    --red-600: 0, 100%, 45%;
    --red-700: 0, 100%, 40%;
    --red-800: 0, 100%, 34%;
    --red-900: 0, 100%, 24%;
    --red-950: 0, 100%, 17%;

    --silver-50: 0, 0%, 98%;
    --silver-100: 0, 0%, 96%;
    --silver-200: 0, 0%, 93%;
    --silver-300: 0, 0%, 91%;
    --silver-400: 0, 0%, 88%;
    --silver-500: 0, 0%, 85%;
    --silver-600: 0, 0%, 68%;
    --silver-700: 0, 0%, 51%;
    --silver-800: 0, 0%, 34%;
    --silver-900: 0, 0%, 17%;
    --silver-950: 0, 0%, 9%;

    --manatee-50: 240, 6%, 97%;
    --manatee-100: 200, 10%, 94%;
    --manatee-200: 216, 9%, 89%;
    --manatee-300: 223, 8%, 82%;
    --manatee-400: 220, 7%, 74%;
    --manatee-500: 220, 7%, 67%;
    --manatee-600: 231, 6%, 57%;
    --manatee-700: 230, 5%, 51%;
    --manatee-800: 233, 4%, 41%;
    --manatee-900: 231, 4%, 34%;
    --manatee-950: 225, 4%, 20%;

    /* --background: 240 5% 96%; */
    /* --background: var(--azure-radiance-background); */
    --background: 210, 100%, 99%;
    /* --foreground: 0 0% 4%; */
    --foreground: var(--red-500);

    /* --card: 0 0% 98%; */
    --card: 0 0% 100%;
    /* --card-foreground: 0 0% 4%; */
    --card-foreground: var(--manatee-900);

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 4%;

    --primary: var(--red-500);
    --primary-50: var(--red-50);
    --primary-100: var(--red-100);
    --primary-200: var(--red-200);
    --primary-300: var(--red-300);
    --primary-400: var(--red-400);
    --primary-500: var(--red-500);
    --primary-600: var(--red-600);
    --primary-700: var(--red-700);
    --primary-800: var(--red-800);
    --primary-900: var(--red-900);
    --primary-950: var(--red-950);
    --primary-foreground: 0 0% 98%;
    
    --base: var(--silver-950);
    --secondary: var(--black);
    --secondary-50: var(--silver-50);
    --secondary-100: var(--silver-100);
    --secondary-200: var(--silver-200);
    --secondary-300: var(--silver-300);
    --secondary-400: var(--silver-400);
    --secondary-500: var(--silver-500);
    --secondary-600: var(--silver-600);
    --secondary-700: var(--silver-700);
    --secondary-800: var(--silver-800);
    --secondary-900: var(--silver-900);
    --secondary-950: var(--silver-950);
    --secondary-foreground: var(--manatee-900);

    --green-50: 167, 86%, 97%;
    --green-100: 168, 83%, 94%;
    --green-200: 168, 65%, 88%;
    --green-300: 168, 69%, 78%;
    --green-400: 168, 71%, 72%;
    --green-500: 168, 67%, 62%;
    --green-600: 168, 65%, 48%;
    --green-700: 168, 65%, 43%;
    --green-800: 168, 64%, 35%;
    --green-900: 167, 64%, 26%;
    --green-950: 167, 64%, 19%;

    --yellow-50: 52, 75%, 97%;
    --yellow-100: 48, 82%, 94%;
    --yellow-200: 47, 77%, 88%;
    --yellow-300: 47, 77%, 81%;
    --yellow-400: 47, 77%, 74%;
    --yellow-500: 46, 77%, 65%;
    --yellow-600: 46, 77%, 50%;
    --yellow-700: 46, 77%, 44%;
    --yellow-800: 46, 77%, 36%;
    --yellow-900: 46, 77%, 26%;
    --yellow-950: 46, 77%, 18%;

    /* --muted: 240 6% 90%;
    --muted-foreground: 0 0% 40%; */

    --muted: 0 0% 100%;
    --muted-foreground: var(--manatee-900);

    --accent: 240 6% 84%;
    /* --accent-foreground: 0 0% 8%; */
    --accent-foreground: var(--azure-radiance-700);

    --destructive: 0 84% 64%;
    --destructive-foreground: 0 0% 98%;

    --hover: 240 6% 96%;
    /* --border: 240 6% 96%; */
    --border: var(--manatee-300);
    --input: 240 6% 84%;
    --ring: 0 0% 4%;

    --field: var(--azure-radiance-trace);

    --radius: 0.5rem;

    --font-heading: "Grandview", sans-serif;

    --white: 0, 0%, 100%;
    --black: 0, 0%, 0%;
    
    label {
      @apply text-secondary-900;
    }
    hr {
      @apply bg-secondary-300;
    }
    textarea,
    input {
      @apply border-secondary-400 text-black file:border-secondary-950 placeholder:text-secondary-600 focus:border-secondary-950 disabled:border-secondary-400 disabled:bg-secondary-100 disabled:text-secondary-600;
    }
    body {
      @apply bg-secondary-50;
    }
    label.text-secondary-800 {
      color: var(--secondary-800) !important;
    }
    label.text-secondary {
      color: var(--secondary) !important;
    }
    .recharts-tooltip-wrapper .tabular-nums {
      color: rgb(51 51 51);
      font-weight: 700;
    }
  
    .recharts-tooltip-wrapper .leading-none {
      color: rgb(51 51 51);;
      font-weight: 700;
    }
  
    .recharts-tooltip-wrapper .leading-none .text-muted-foreground {
      color: rgba(133, 133, 133, 1);
      font-weight: 600;
    }

    .auto-complete-multi-select .custom-dropdown-item.focused {
      background-color: hsl(var(--secondary-100));
    }
  }
  
  @font-face {
    font-family: 'Grandview';
    src: url('../fonts/Grandview.eot');
    src: url('../fonts/Grandview.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Grandview.woff2') format('woff2'),
        url('../fonts/Grandview.woff') format('woff'),
        url('../fonts/Grandview.ttf') format('truetype'),
        url('../fonts/Grandview.svg#Grandview') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Grandview';
    src: url('../fonts/Grandview-Bold.eot');
    src: url('../fonts/Grandview-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Grandview-Bold.woff2') format('woff2'),
        url('../fonts/Grandview-Bold.woff') format('woff'),
        url('../fonts/Grandview-Bold.ttf') format('truetype'),
        url('../fonts/Grandview-Bold.svg#Grandview-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  /* Add this to set base font styles */
  html, body {
    min-height: 100vh;
    color: hsl(var(--base));
    font-family: 'Grandview';
    font-weight: normal;    
    font-style: normal;
  }
  
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3 {
    margin-bottom: 0;
    font-weight: bold;
    color: hsl(var(--secondary));
  }
  h1, .h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h2, .h2, h3, .h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .dark {
    --background: 240 6% 10%;
    --foreground: 0 0% 98%;

    --card: 240 6% 6%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 4%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 240 6% 20%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 6% 20%;
    --muted-foreground: 0 0% 56%;

    --accent: 240 5% 32%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62% 64%;
    --destructive-foreground: 0 0% 98%;

    --hover: 240 6% 10%;
    --border: 240 4% 10%;
    --input: 240 5% 26%;
    --ring: 0 0% 83.1%;

    --field: 0 0% 0%;
  }
}

body[data-scroll-locked="1"][data-gr-ext-installed] {
  margin-right: 0 !important;
  overflow: auto !important;
}

/* .grid-cols-1 .grid-cols-1 > * {
  width: 350px;
} */
.elementor-widget-container ol {
  list-style: decimal;
}
.elementor-widget-container ul,
.elementor-widget-container ol {
  margin: 0 0 1.4em 20px;
}
.elementor-widget-container p {
  margin-bottom: 17px;
}
.elementor-widget-container ul {
  list-style: disc;
}
.customSelect button[data-state="checked"] {
  padding: 2px;
}
.customSelect button[data-state="checked"] span {
  background-color: black;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}
.customSelect button[data-state="checked"] span svg {
  display: none;
}
.customSelect button svg:last-child,
button.customSelect svg:last-child {
  display: none;
}
.formNumber {
  padding: 0 40px 20px;
  border-bottom: 5px solid hsl(var(--secondary-300));
}
.formNumber.tab-active {
  border-bottom: 5px solid hsl(var(--primary));
}
.btn-week-month {
  background-color: hsl(var(--secondary-100)) !important;
  color: hsl(var(--primary)) !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: normal !important;
}
.btn-week-month:hover, .btn-week-month:focus, .btn-week-month:active, .btn-week-month-active {
  background-color: hsl(var(--primary)) !important;
  color: hsl(var(--white)) !important;
  box-shadow: none !important;
  font-weight: normal !important;
}
.btn-day {
  background-color: hsl(var(--secondary-100)) !important;
  color: var(--secondary) !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: normal !important;
}
.btn-day:hover, .btn-day:focus, .btn-day:active, .btn-day-active {
  background-color: hsl(var(--primary)) !important;
  color: hsl(var(--white)) !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: normal !important;
}
.calendar-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23007AFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m6 9 6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right center;
  width: 110px;
}
input[type="date" i].calendar-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

.datepicker-native {
  position: relative;
}
.datepicker-native-input {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0;
}
.datepicker-native-input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}
.rounded-0 > .rounded-lg {
  border-radius: 0 !important;
}
.custom-radio-group button[data-state="checked"] span svg {
  display: none;
}
.custom-radio-group button[data-state="checked"] span {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
  background: hsl(var(--secondary));
}
@media (max-width: 768px) {
  .header img {
    max-width: 120px;
    height: auto;
  }
}
@media screen and (max-height: 610px) {
  .modal-theme {
    max-height: 100vh;
    overflow: auto;
  }
}

/* ===== Scrollbar CSS start ===== */
/* Firefox */
* {
  scrollbar-width: auto;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: hsl(var(--secondary-300));
  border-radius: 10px;
}

.home-products-navbar .active-btn {
  background: transparent !important;
  color: hsl(var(--primary)) !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem 0rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: none !important;
  position: relative !important;
}
.home-products-navbar .active-btn::after,
.home-products-navbar button:nth-child(1):hover::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background: hsl(var(--primary)) !important;
  display: block !important;
}
.home-products-navbar button:nth-child(1) {
  background: transparent !important;
  padding: 0.5rem 0rem !important;
}
.home-products-navbar button:nth-child(1):hover {
  color: hsl(var(--primary)) !important;
}
.home-products-navbar button:nth-child(1):hover::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background: hsl(var(--primary)) !important;
  display: block !important;
}

.left-nav img {
  opacity: .8;
}
.left-nav:not(.engage-icon) a:hover img {
  filter: invert(1);
  opacity: 1;
}
.left-nav.engage-icon a.text-gray-600:hover img {
  filter: invert(1);
  opacity: 1;
}
.left-nav a.shadow-md:hover img {
  filter: invert(0);
  opacity: 1;
}
.custom-checkbox-hide button:disabled {
  opacity: 0 !important;
}
.\[\&\:has\(\[data-state\=checked\]\)\]\:bg-\[\#EFF7FF\]:has([data-state=checked]) .rounded-md.border.p-3 {
  border: 1px solid hsl(var(--primary));
  background: hsl(var(--white));
}
.\[\&\:has\(\[data-state\=checked\]\)\]\:bg-\[\#EFF7FF\]:has([data-state=checked]) .rounded-md.border.p-3 svg {
  stroke: hsl(var(--primary));
}

span.flatpickr-day.today:not(.selected):hover, span.flatpickr-day.prevMonthDay.today:not(.selected):hover, span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid hsl(var(--primary)) !important;
}

span.flatpickr-day.today:not(.selected), span.flatpickr-day.prevMonthDay.today:not(.selected), span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-bottom-color: hsl(var(--primary)) !important;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: hsl(var(--primary)) !important;
  background: hsl(var(--primary)) !important;
  color: hsl(var(--white)) !important;
}
span.flatpickr-day.today.flatpickr-disabled {
  color: #333 !important;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: hsl(var(--primary)) !important;
  border-color: hsl(var(--primary)) !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: hsl(var(--primary)) !important;
}
@media (min-width: 992px) {
  aside#drawer-navigation.lg\:w-16 div#sidebar-usage-summary {
    display: none;
  }
  aside#drawer-navigation.lg\:w-16 .ml-3 {
    margin-left: 0;
  }
  aside#drawer-navigation.lg\:w-16 img.mr-3 {
    height: 24px;
    width: 24px;
  }  
  aside#drawer-navigation.lg\:w-16 #sidebar-usage-summary-dev {
    display: flex;
  }
}
@media (max-width: 991px) {
  aside#drawer-navigation.lg\:w-16 #sidebar-usage-summary {
    display: none;
  }
}
#sidebar-usage-summary-dev {
  display: none;
}
.engage-icon a {
  justify-content: start !important;
}
@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Regular.eot');
  src: url('../fonts/Barlow-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Barlow-Regular.woff2') format('woff2'),
      url('../fonts/Barlow-Regular.woff') format('woff'),
      url('../fonts/Barlow-Regular.ttf') format('truetype'),
      url('../fonts/Barlow-Regular.svg#Barlow-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.font-Barlow {
  font-family: 'Barlow';
}
.recharts-responsive-container:active,
.recharts-wrapper:active,
.recharts-responsive-container:target,
.recharts-wrapper:target,
.recharts-responsive-container:hover,
.recharts-wrapper:hover,
.recharts-responsive-container:focus,
.recharts-wrapper:focus,
.recharts-responsive-container:focus-visible,
.recharts-wrapper:focus-visible,
.recharts-responsive-container:focus-within,
.recharts-wrapper:focus-within,
.recharts-wrapper svg:focus {
  outline: none !important;
}
.form-checkbox {
  appearance: none;
  border: 2px solid hsl(var(--secondary-950)) !important;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;
}

.form-checkbox:checked {
  background-color: hsl(var(--secondary-950));
  border-color: hsl(var(--secondary-950));
}

.form-checkbox:checked::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.autocomplete-focused {
  border-color: hsl(var(--secondary-950)) !important;
  box-shadow: none !important;
}

[class*="css-"][class*="-multiValue"] {
  border-radius: 1rem !important;
}
[class*="css-"][class*="-multiValue"] [role="button"] {
  border-radius: 0 1rem 1rem 0 !important;
}


